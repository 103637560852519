<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#00C3FF;"
      d="M305.865,72.35c-1.373-10.174-6.619-19.203-14.78-25.428c-16.865-12.86-41.025-9.598-53.864,7.25
	c-9.114,11.942-21.023,44.931-28.95,68.903c-7.926-23.972-19.836-56.961-28.95-68.903c-12.848-16.852-37.008-20.101-53.864-7.25
	c-8.161,6.224-13.406,15.255-14.78,25.428c-1.365,10.174,1.305,20.272,7.534,28.432c14.22,18.652,78.559,54.627,85.855,58.665
	c1.314,0.729,2.763,1.089,4.203,1.089c1.437,0,2.816-0.473,4.1-1.173l0.103,0.084c7.297-4.039,71.636-40.013,85.855-58.665
	C304.56,92.622,307.229,82.524,305.865,72.35z M132.008,90.257c-3.415-4.475-4.881-10.012-4.127-15.593
	c0.754-5.581,3.627-10.53,8.102-13.944c3.814-2.903,8.296-4.31,12.754-4.31c6.356,0,12.644,2.856,16.789,8.289
	c7.695,10.089,19.703,43.737,27.822,68.615C168.06,118.389,139.195,99.685,132.008,90.257z M284.534,90.257
	c-7.186,9.42-36.043,28.127-61.339,43.051c8.118-24.873,20.127-58.521,27.822-68.61c4.144-5.432,10.432-8.289,16.789-8.289
	c4.45,0,8.949,1.407,12.754,4.31c4.475,3.415,7.347,8.364,8.102,13.944C289.417,80.244,287.949,85.782,284.534,90.257z"
    />
    <g>
      <path
        style="fill:#00AAF0;"
        d="M208.271,169.214c-1.093,0-2.187-0.207-3.221-0.618l-108.475-43.39
		c-4.45-1.784-6.619-6.831-4.839-11.284c1.78-4.453,6.873-6.597,11.279-4.835l105.255,42.101l105.254-42.102
		c4.407-1.758,9.5,0.382,11.279,4.835c1.779,4.453-0.389,9.5-4.839,11.284l-108.475,43.39
		C210.458,169.007,209.365,169.214,208.271,169.214z"
      />
      <path
        style="fill:#00AAF0;"
        d="M477.288,299.384H373.153v121.492h130.169c4.792,0,8.678-3.886,8.678-8.678v-78.102
		C512,314.925,496.459,299.384,477.288,299.384z"
      />
    </g>
    <path
      style="fill:#0096DC;"
      d="M373.153,316.74h104.136c9.586,0,17.356,7.77,17.356,17.356v86.78H373.153V316.74z"
    />
    <path
      style="fill:#007DC8;"
      d="M493.954,420.875c-4.221-29.427-29.462-52.068-60.056-52.068s-55.835,22.641-60.056,52.068H493.954z"
    />
    <path
      style="fill:#00AAF0;"
      d="M442.576,195.248h-69.424v34.712h91.119c2.396,0,4.339-1.943,4.339-4.339v-4.339
	C468.61,206.904,456.955,195.248,442.576,195.248z"
    />
    <path
      style="fill:#00C3FF;"
      d="M364.475,160.536H52.068c-4.792,0-8.678,3.886-8.678,8.678v173.559H26.034
	c-4.797,0-8.678,3.886-8.678,8.678c0,4.792,3.881,8.678,8.678,8.678h52.068c4.797,0,8.678,3.886,8.678,8.678
	s-3.881,8.678-8.678,8.678H43.39v34.712c0,4.792,3.886,8.678,8.678,8.678h321.085V169.214
	C373.153,164.422,369.267,160.536,364.475,160.536z"
    />
    <path
      style="fill:#00AAF0;"
      d="M178.501,377.486c-9.087-5.427-19.619-8.678-30.976-8.678c-11.357,0-21.889,3.251-30.976,8.678H8.678
	c-4.797,0-8.678,3.886-8.678,8.678c0,4.792,3.881,8.678,8.678,8.678H43.39v17.356c0,4.792,3.886,8.678,8.678,8.678h321.085v-43.39
	H178.501z"
    />
    <g>
      <circle style="fill:#5B5D6E;" cx="433.898" cy="429.55" r="43.39" />
      <circle style="fill:#5B5D6E;" cx="147.525" cy="429.55" r="43.39" />
    </g>
    <polygon
      style="fill:#B4E6FF;"
      points="477.288,299.384 459.932,229.96 373.153,229.96 373.153,299.384 "
    />
    <circle style="fill:#C7CFE2;" cx="147.525" cy="429.55" r="26.034" />
    <circle style="fill:#7F8499;" cx="147.525" cy="429.55" r="8.678" />
    <circle style="fill:#C7CFE2;" cx="433.898" cy="429.55" r="26.034" />
    <circle style="fill:#7F8499;" cx="433.898" cy="429.55" r="8.678" />
    <path
      style="fill:#9BD6FF;"
      d="M407.864,247.316h56.407l-4.339-17.356h-86.78v69.424h26.034v-43.39
	C399.186,251.202,403.072,247.316,407.864,247.316z"
    />
    <path
      style="fill:#0096DC;"
      d="M373.153,212.604h69.424c4.792,0,8.678,3.886,8.678,8.678v8.678h-78.102V212.604z"
    />
    <rect
      x="485.966"
      y="334.092"
      style="fill:#F1F4FB;"
      width="26.034"
      height="34.712"
    />
    <path
      style="fill:#FFC850;"
      d="M468.61,334.096v17.356c0,9.585,7.771,17.356,17.356,17.356h8.678v-34.712H468.61z"
    />
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M130.169,221.282H95.458c-4.797,0-8.678,3.881-8.678,8.678v69.424c0,4.797,3.881,8.678,8.678,8.678
		s8.678-3.881,8.678-8.678V273.35h17.356c4.797,0,8.678-3.881,8.678-8.678s-3.881-8.678-8.678-8.678h-17.356v-17.356h26.034
		c4.797,0,8.678-3.881,8.678-8.678S134.966,221.282,130.169,221.282z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M208.271,251.655c0-16.746-13.628-30.373-30.373-30.373h-21.695c-4.797,0-8.678,3.881-8.678,8.678
		v69.424c0,4.797,3.881,8.678,8.678,8.678c4.797,0,8.678-3.881,8.678-8.678v-17.356h12.712l14.78,22.17
		c1.669,2.509,4.424,3.865,7.229,3.865c1.652,0,3.323-0.475,4.805-1.458c3.992-2.661,5.068-8.042,2.407-12.034l-11.868-17.802
		C202.979,271.298,208.271,262.085,208.271,251.655z M164.881,238.638h13.017c7.178,0,13.017,5.839,13.017,13.017
		s-5.839,13.017-13.017,13.017h-13.017V238.638z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M260.339,238.638c4.797,0,8.678-3.881,8.678-8.678s-3.881-8.678-8.678-8.678h-34.712
		c-4.797,0-8.678,3.881-8.678,8.678v69.424c0,4.797,3.881,8.678,8.678,8.678h34.712c4.797,0,8.678-3.881,8.678-8.678
		c0-4.797-3.881-8.678-8.678-8.678h-26.034V273.35h17.356c4.797,0,8.678-3.881,8.678-8.678s-3.881-8.678-8.678-8.678h-17.356
		v-17.356L260.339,238.638L260.339,238.638z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M321.085,290.706h-26.034V273.35h8.678c4.797,0,8.678-3.881,8.678-8.678s-3.881-8.678-8.678-8.678
		h-8.678v-17.356h26.034c4.797,0,8.678-3.881,8.678-8.678s-3.881-8.678-8.678-8.678h-34.712c-4.797,0-8.678,3.881-8.678,8.678
		v69.424c0,4.797,3.881,8.678,8.678,8.678h34.712c4.797,0,8.678-3.881,8.678-8.678C329.763,294.587,325.882,290.706,321.085,290.706
		z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
